import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import TitleWithCatchCopy from '~/components/TitleWithCatchCopy';
import { NoImgTopSection, Section, media, colors } from '~/styles';
import Card from '~/components/careers/Card';

const CareersPage: React.VFC<PageProps<GatsbyTypes.CareersPageQuery>> = ({
  data: { rawCareers },
  location,
}) => {
  const { t, i18n } = useTranslation();
  const [showCareer, setShowCareer] = useState<Set<string>>(new Set());

  const careers = useMemo(
    () =>
      rawCareers.nodes.map((career) => {
        const careerJa = {
          jobCategory: career.jobCategory,
          applicationConditions: career.applicationConditions,
          basicSalary: career.basicSalary,
          employmentStatus: career.employmentStatus,
          holidayVacation: career.holidayVacation,
          jobDescription: career.jobDescription,
          position: career.position,
          welfare: career.welfare,
          treatment: career.treatment,
        };

        const careerDataEn =
          career.i18n_refs && career.i18n_refs?.length > 0
            ? career.i18n_refs[0]?.ref
            : null;

        const careerEn = careerDataEn
          ? {
              jobCategory: careerDataEn.jobCategory,
              applicationConditions: careerDataEn.applicationConditions,
              basicSalary: careerDataEn.basicSalary,
              employmentStatus: careerDataEn.employmentStatus,
              holidayVacation: careerDataEn.holidayVacation,
              jobDescription: careerDataEn.jobDescription,
              position: careerDataEn.position,
              welfare: careerDataEn.welfare,
              treatment: careerDataEn.treatment,
            }
          : null;

        return {
          slug: career.slug?.current,
          localizedItem:
            i18n.language === 'en' && careerEn ? careerEn : careerJa,
        };
      }),
    [i18n.language, rawCareers],
  );

  const artistCareers = useMemo(
    () =>
      careers.filter((career) => career.localizedItem.jobCategory === 'artist'),
    [careers],
  );
  const officeCareers = useMemo(
    () =>
      careers.filter((career) => career.localizedItem.jobCategory === 'office'),
    [careers],
  );

  const handleChangeCard = useCallback(
    (slug: string) => {
      const nextSet = new Set(Array.from(showCareer));

      if (nextSet.has(slug)) {
        nextSet.delete(slug);
      } else {
        nextSet.add(slug);
      }

      setShowCareer(nextSet);
    },
    [showCareer],
  );

  useEffect(() => {
    const hash = location.hash.replace('#', '');

    if (!!hash && careers) {
      const currentCareer = careers.find((career) => career.slug === hash);
      currentCareer && handleChangeCard(currentCareer.slug ?? '');
    }
  }, [location.hash, careers]);

  return (
    <>
      <SEO title="Careers" />
      <Layout>
        <NoImgTopSection>
          <TitleWithCatchCopy
            title="careers.title"
            catchCopy="careers.catchCopy"
            byKey
            isIntersection
          />
        </NoImgTopSection>
        <Section>
          <CategorizedCareers>
            <CareerCategoryTitle>
              {t('careers.category.artist')}
            </CareerCategoryTitle>
            <List>
              {artistCareers.map((career) => (
                <Card
                  career={career}
                  onChange={() => handleChangeCard(career.slug ?? '')}
                  show={showCareer.has(career.slug ?? '')}
                />
              ))}
            </List>
          </CategorizedCareers>
          <CategorizedCareers>
            <CareerCategoryTitle>
              {t('careers.category.office')}
            </CareerCategoryTitle>
            <List>
              {officeCareers.map((career) => (
                <Card
                  career={career}
                  onChange={() => handleChangeCard(career.slug ?? '')}
                  show={showCareer.has(career.slug ?? '')}
                />
              ))}
            </List>
          </CategorizedCareers>
        </Section>
      </Layout>
    </>
  );
};

const List = styled.ul`
  display: grid;
  grid-gap: 96px;
  width: 100%;
  justify-content: start;
  margin: 0;
  padding: 0;

  ${media.mdDown} {
    grid-gap: 72px;
  }
`;

const CategorizedCareers = styled.div`
  display: grid;
  gap: 80px;
  width: 100%;
`;

const CareerCategoryTitle = styled.h4`
  font-weight: normal;
  font-size: 24px;
  white-space: pre-wrap;
  margin: 0;
  color: ${colors.white};

  position: relative;

  &::before {
    content: '';
    width: 100%;
    position: absolute;
    height: 1px;
    inset: auto 0 -16px;
    margin: auto auto 0;
    background-color: ${colors.white};
  }

  ${media.smDown} {
    font-size: 20px;

    &::before {
      inset: auto 0 -8px;
    }
  }
`;

export default CareersPage;

export const query = graphql`
  query CareersPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    rawCareers: allSanityCareer(
      filter: { i18n_lang: { eq: "ja" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        jobCategory
        applicationConditions
        basicSalary
        employmentStatus
        holidayVacation
        id
        treatment
        welfare
        position
        jobDescription
        slug {
          current
        }
        i18n_refs {
          ref {
            ... on SanityCareer {
              id
              jobCategory
              applicationConditions
              basicSalary
              employmentStatus
              holidayVacation
              jobDescription
              position
              welfare
              treatment
            }
          }
        }
      }
    }
  }
`;
