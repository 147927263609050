import React from 'react';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import styled, { css } from 'styled-components';
import { commonKeyframes, easings, media } from '~/styles';
import { useIntersection } from '~/hooks';
import TextBreaks from '~/components/TextBreaks';

interface CardProps {
  career: {
    slug: string;
    localizedItem: {
      jobCategory: string;
      applicationConditions: string;
      basicSalary: string;
      employmentStatus: string;
      holidayVacation: string;
      jobDescription: string;
      position: string;
      welfare: string;
      treatment: string;
    };
  };
  onChange: () => void;
  show: boolean;
}

const Card: React.VFC<CardProps> = ({ career, onChange, show }) => {
  const { t } = useTranslation();
  const [isIntersection, intersectionRef] = useIntersection<HTMLLIElement>();

  return (
    <Wrapper id={`${career.slug}`} ref={intersectionRef}>
      <Position isIntersection={isIntersection} onClick={onChange} show={show}>
        <ToggleIcon show={show} />
        {career.localizedItem.position}
      </Position>
      <Details isIntersection={isIntersection} show={show}>
        <DetailItem>
          <DetailTitle>{t('careers.card.description')}</DetailTitle>
          <dd>{lineBreak(career.localizedItem.jobDescription)}</dd>
        </DetailItem>
        <DetailItem>
          <DetailTitle>{t('careers.card.conditions')}</DetailTitle>
          <dd>{lineBreak(career.localizedItem.applicationConditions)}</dd>
        </DetailItem>
        <DetailItem>
          <DetailTitle>{t('careers.card.basicSalary')}</DetailTitle>
          <dd>{lineBreak(career.localizedItem.basicSalary)}</dd>
        </DetailItem>
        <DetailItem>
          <DetailTitle>{t('careers.card.employmentStatus')}</DetailTitle>
          <dd>{lineBreak(career.localizedItem.employmentStatus)}</dd>
        </DetailItem>
        <DetailItem>
          <DetailTitle>{t('careers.card.holidayVacation')}</DetailTitle>
          <dd>{lineBreak(career.localizedItem.holidayVacation)}</dd>
        </DetailItem>
        <DetailItem>
          <DetailTitle>{t('careers.card.welfare')}</DetailTitle>
          <dd>{lineBreak(career.localizedItem.welfare)}</dd>
        </DetailItem>
        <DetailItem>
          <DetailTitle>{t('careers.card.treatment')}</DetailTitle>
          <dd>{lineBreak(career.localizedItem.treatment)}</dd>
        </DetailItem>
        <Note>
          <Trans i18nKey="careers.note" components={TextBreaks} />
        </Note>
        <ApplyButton to="/contact">{t('careers.apply')}</ApplyButton>
      </Details>
    </Wrapper>
  );
};

function lineBreak(text: string) {
  return (
    text?.split('\n').map((str, index) => (
      <React.Fragment key={index}>
        {str}
        <br />
      </React.Fragment>
    )) ?? <></>
  );
}

const Note = styled.p`
  font-size: 0.9rem;
  width: 80%;
  margin-inline: auto;
  border: 1px solid #808080;
  padding: 16px;
  border-radius: 6px;
`;

const ApplyButton = styled(Link)`
  display: block;
  padding: 16px 48px;
  border-radius: 100px;
  border: 1px solid #616161;
  color: #ffffff;
  background-color: #1f1f1f;
  width: fit-content;
  transition-duration: 0.3s;
  margin-inline: auto;

  &:hover {
    border-color: #fff;
    color: #fff;
    background-color: #000000;
    transition-duration: 0.6s;
  }
`;

const Wrapper = styled.li`
  display: grid;
  width: 100%;
  //padding-top: 163px;
  //margin-top: -163px;

  ${media.mdUp} {
    grid-gap: 40px;
  }

  ${media.mdDown} {
    grid-gap: 24px;
  }
`;

const Position = styled.div<{
  isIntersection: boolean;
  show: boolean;
}>`
  position: relative;
  display: inline-block;
  font-size: 36px;
  line-height: 1.5;
  white-space: pre-wrap;
  margin-left: 32px;
  opacity: 0;
  cursor: pointer;

  ${media.mdDown} {
    font-size: 24px;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} forwards;
    `}
`;

const ToggleIcon = styled.span<{
  show: boolean;
}>`
  position: absolute;
  display: inline-block;
  height: 24px;
  width: 24px;
  top: 13px;
  left: -32px;

  &::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #fff;
    inset: 0;
    margin: auto;
  }

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #fff;
    inset: 0;
    margin: auto;
    transition: transform 0.3s ${easings.easeOutCubic};
    transform: rotate(0);
  }

  ${media.mdDown} {
    top: 6px;
    height: 20px;
    width: 20px;
  }

  ${({ show }) =>
    show &&
    css`
      &::after {
        transition-duration: 0.15s;
        transform: rotate(90deg);
      }
    `}
`;

const DetailItem = styled.dl`
  display: grid;
  grid-template-columns: 4em auto;
  grid-gap: 3em;
  line-height: 2;
  opacity: 0;
  transform: translateY(3em);

  ${media.mdDown} {
    grid-gap: 16px;
    grid-template-columns: 100%;
    font-size: 14px;
  }
`;

const Details = styled.div<{
  isIntersection: boolean;
  show: boolean;
}>`
  display: grid;
  grid-gap: 48px;
  justify-content: start;

  ${media.mdDown} {
    grid-gap: 32px;
  }

  ${({ show }) =>
    !show &&
    css`
      display: none;
    `}

  ${({ isIntersection, show }) =>
    show &&
    isIntersection &&
    css`
      ${DetailItem} {
        --delay-offset: 0s;
        animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic}
            forwards,
          ${commonKeyframes.slideIn} 0.6s ${easings.easeOutCubic} forwards;

        &:nth-child(1) {
          --animation-delay: var(--delay-offset);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }

        &:nth-child(2) {
          --animation-delay: calc(var(--delay-offset) + 0.07s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }

        &:nth-child(3) {
          --animation-delay: calc(var(--delay-offset) + 0.15s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }

        &:nth-child(4) {
          --animation-delay: calc(var(--delay-offset) + 0.22s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }

        &:nth-child(5) {
          --animation-delay: calc(var(--delay-offset) + 0.3s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }

        &:nth-child(6) {
          --animation-delay: calc(var(--delay-offset) + 0.37s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }

        &:nth-child(7) {
          --animation-delay: calc(var(--delay-offset) + 0.45s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
      }
    `}
`;

const DetailTitle = styled.dt`
  text-align: justify;
  text-align-last: justify;

  ${media.mdDown} {
    font-size: 16px;
    text-align: left;
    text-align-last: left;
  }
`;

export default Card;
